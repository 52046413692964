import {
  Button,
  Flex,
  Heading,
  IconSize,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React, { FC, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ProtectOptions } from './ProtectOptions/ProtectOptions';
import { ProtectSlider } from './ProtectSlider/ProtectSlider';
import { ProtectSummary } from './ProtectSummary/ProtectSummary';
import { ProtectUnitsCard } from './ProtectUnits/ProtectUnitsCard';

import { STARTING_BALANCE_ETH } from '../../commons/config';
import { DefaultLocationProps } from '../../interfaces/location';
import { formatStringifyNumberToDot } from '../../utils/helpers';
import { MainContainer } from '../common/MainContainer';
import { DataFeedContext } from '../context/DataFeedContext';
import { Routes } from '../routes/routes';

export const ProtectContent: FC = () => {
  const navigate = useNavigate();
  const { simulationDataFeed } = useContext(DataFeedContext);
  const state = useLocation().state as DefaultLocationProps;
  const { isMobile } = useMatchBreakpoints();
  const floors = [70, 80, 85, 90, 95];
  const terms = [30, 60, 90];
  const [activeFloor, setActiveFloor] = useState<number | null>(null);
  const [activeTerm, setActiveTerm] = useState<number | null>(null);
  const [protectAmount, setProtectAmount] = useState<number>(0.0001);
  const onConfirm = () => {
    if (activeFloor !== null && activeTerm !== null) {
      navigate(Routes.Run, {
        state: {
          ...state,
          protectDetails: {
            floor: floors[activeFloor],
            term: terms[activeTerm],
            protectAmount,
          },
        },
        replace: true,
      });
    }
  };

  if (simulationDataFeed?.length) {
    return (
      <MainContainer
        width={isMobile ? '90%' : '650px'}
        py="60px"
        style={{ gap: '40px' }}
      >
        <Flex justifyContent="start" width="100%">
          <Heading scale={'xxl'} color="typography.input">
            Protect {}
          </Heading>
        </Flex>
        <ProtectUnitsCard
          balance={formatStringifyNumberToDot(STARTING_BALANCE_ETH)}
          balanceInUsd={parseFloat(simulationDataFeed[0]?.price).toFixed(2)}
        />
        <ProtectSlider
          balance={parseFloat(STARTING_BALANCE_ETH)}
          price={parseFloat(simulationDataFeed[0]?.price)}
          value={protectAmount}
          setValue={setProtectAmount}
        />
        <ProtectOptions
          title="Protection Floor"
          description="Your chosen floor indicates at what price your protection will activate below the current price. Lower floors have a lower premium."
          units="%"
          options={floors}
          activeOption={activeFloor}
          onChange={(e) => setActiveFloor(e)}
          disabled={protectAmount === 0}
        />
        <ProtectOptions
          title="Protection Term"
          description="Your protection will be active for a fixed period, after which
  you are able to either make a stablecoin claim, or withdraw your deposited asset, minus your premium."
          units=" days"
          options={terms}
          activeOption={activeTerm}
          onChange={(e) => setActiveTerm(e)}
          disabled={activeFloor === null || protectAmount === 0}
        />
        {simulationDataFeed?.length > 0 ? (
          <ProtectSummary
            depositValue={(
              protectAmount * parseFloat(simulationDataFeed[0].price)
            ).toFixed(2)}
            floorPrice={(
              (floors[activeFloor ?? 0] / 100) *
              parseFloat(simulationDataFeed[0].price)
            ).toFixed(2)}
            protectedValue={(
              (floors[activeFloor ?? 0] / 100) *
              parseFloat(simulationDataFeed[0].price) *
              protectAmount
            ).toFixed(2)}
            currentPrice={simulationDataFeed[0].price}
            disabled={
              activeFloor === null || activeTerm === null || protectAmount === 0
            }
          />
        ) : null}

        <Flex width="100%" alignItems="center" justifyContent="space-between">
          <Button
            size={IconSize.XL}
            height="48px"
            minHeight="unset"
            secondary
            onClick={() => navigate(Routes.WalletPage, { state })}
          >
            Back
          </Button>
          <Button
            size={IconSize.XL}
            height="48px"
            minHeight="unset"
            primary
            disabled={
              protectAmount <= 0 ||
              activeFloor === null ||
              activeTerm === null ||
              protectAmount === 0
            }
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </Flex>
      </MainContainer>
    );
  }
  return null;
};
