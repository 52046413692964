import React, { FC } from 'react';
import styled from 'styled-components';

import cryptoDefenderLogo from '../../assets/logo/Crypto-Defender-Logo.svg';

interface LogoPropsType {
  size: number;
}

export const CryptoDefenderLogo: FC<LogoPropsType> = (props) => {
  return <Logo src={cryptoDefenderLogo} alt="logo" {...props} />;
};

export const Logo = styled('img')<LogoPropsType>`
  height: ${({ size }) => size}px;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 16px;
  }
`;
