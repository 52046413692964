import { useMatchBreakpoints } from '@bumper-dao/ui-kit';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { PriceSimulator } from './PriceSimulator';

import { ProtectLocationProps } from '../../interfaces/location';
import { MainContainer } from '../common/MainContainer';

export const RunContent: React.FC = () => {
  const { isMobile } = useMatchBreakpoints();
  // Received from Protect Content
  const { protectDetails } = useLocation().state as ProtectLocationProps;
  return (
    <MainContainer width={isMobile ? '90%' : '708px'} py={'60px'}>
      <PriceSimulator {...protectDetails} />
    </MainContainer>
  );
};
