import { Flex, Box, useMatchBreakpoints } from '@bumper-dao/ui-kit';
import React, { FC } from 'react';
import styled from 'styled-components';

export const HeaderFooter: FC = () => {
  const { isMobile } = useMatchBreakpoints();
  return (
    <Wrapper>
      {!isMobile && (
        <>
          <HorizontalLine />
          <Separator />
          <Separator />
          <Box width="200%">
            <HorizontalLine />
          </Box>
          <Separator />
          <Separator />
        </>
      )}
      <HorizontalLine />
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  padding: 24px 0;
  width: 100%;
  align-items: center;
  gap: 16px;
  background-color: ${({ theme }) => theme.colors.primary2.greenCyprus};
  border-top: 4px solid ${({ theme }) => theme.colors.primary2.myrtleGreen};
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 16px;
  }
`;

const HorizontalLine = styled.div`
  height: 0;
  width: 100%;
  border-bottom: 4px solid ${({ theme }) => theme.colors.primary2.darkGreen};
  border-top: 4px solid ${({ theme }) => theme.colors.primary2.myrtleGreen};
`;

const VerticalLine = styled.div`
  height: 100%;
  width: 0;
  border-right: 4px solid ${({ theme }) => theme.colors.primary2.myrtleGreen};
  border-left: 4px solid ${({ theme }) => theme.colors.primary2.deepTeal};
`;

const SeparatorContainer = styled(Flex)`
  width: 40px;
  height: 32px;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Separator = () => (
  <SeparatorContainer>
    <VerticalLine />
    <VerticalLine />
    <VerticalLine />
  </SeparatorContainer>
);
