import { Flex } from '@bumper-dao/ui-kit';
import styled from 'styled-components';

import { createPixelInsetBorder } from '../../../utils/getPilexeledAtributes';

export const PositionHeader = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  max-width: 956px;
  width: 100%;
  background-color: ${({ theme }) =>
    theme.colors.background.assetConfirmationBox};

  box-shadow: ${({ theme }) =>
    createPixelInsetBorder(
      theme.colors.primary1.teal,
      theme.colors.background.assetConfirmationBox,
      theme.colors.background.assetConfirmationBox,
      4,
    )};

  padding: 1.65rem 1.75rem;
  z-index: 2;
`;

export const PositionContent = styled(Flex)`
  align-items: center;
  flex-direction: column;

  max-width: 928px;
  width: calc(100% - 14px);
  background-color: ${({ theme }) => theme.colors.background.confirmationBox};

  box-shadow: ${({ theme }) =>
    createPixelInsetBorder(
      theme.colors.primary1.teal,
      theme.colors.background.confirmationBox,
      theme.colors.background.confirmationBox,
      4,
    )};
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.75rem;
`;

export const PositionContentToggleWrapper = styled('button')`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary.white};
  background-color: transparent;
  border: none;
`;

export const PositionInfoContainer = styled(Flex)`
  align-items: center;
  flex-direction: column;

  @media only screen and (max-width: 420px) {
    align-items: flex-start;
  }
`;

export const ChartsIcon = styled.img`
  width: 32px;
  height: 32px;
`;
