export const formatStringifyNumberToDot = (value: string, digits?: number) => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: digits !== undefined && digits >= 0 ? digits : 4, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: digits !== undefined && digits >= 0 ? digits : 4, // (causes 2500.99 to be printed as $2,501)
  });
  const numberValue = parseFloat(value) || 0;
  return currencyFormatter.format(numberValue).slice(1);
};
