import {
  FlexWithGap,
  Flex,
  ConfirmationBox,
  DarkLabel,
  InfoArea,
  LargeText,
} from '@bumper-dao/ui-kit';
import ETHIcon from '@bumper-dao/ui-kit/dist/images/48px/tokens/eth.png';
import React from 'react';
import styled from 'styled-components';

import { BalanceInfoProtectProps, ProtectUnitsCardProps } from './types';

import { createPixelInsetBorder } from '../../../utils/getPilexeledAtributes';

const BalanceInfo: React.FC<BalanceInfoProtectProps> = ({
  balance,
  balanceInUsd,
  tokenName,
}) => {
  return (
    <DarkLabel maxWidth="400px" mt="35px">
      <FlexWithGap gap="32px">
        <InfoArea
          title="Balance"
          value={balance}
          subTitle={`${tokenName} ($${(
            parseFloat(balanceInUsd) * parseFloat(balance)
          ).toFixed(2)})`}
        />
      </FlexWithGap>
    </DarkLabel>
  );
};

export const ProtectUnitsCard: React.FC<ProtectUnitsCardProps> = ({
  balanceInUsd,
  balance,
}) => {
  return (
    <ConfirmationBox flexDirection="column">
      <AssetContainer>
        <img src={ETHIcon} width="48px" />
        <LargeText color="secondary.white">ETH (${balanceInUsd})</LargeText>
      </AssetContainer>
      <BalanceInfo
        tokenName={'ETH'}
        balanceInUsd={balanceInUsd}
        balance={balance}
      />
    </ConfirmationBox>
  );
};

const AssetContainer = styled(Flex)`
  width: fit-content;
  align-items: center;
  padding: 4px 12px;
  box-shadow: ${({ theme }) =>
    createPixelInsetBorder(
      theme.colors.primary2.greenCyprus,
      theme.colors.background.confirmationBox,
      theme.colors.background.confirmationBox,
      4,
    )};
  gap: 12px;
`;
