import {
  Button,
  Close,
  FlexWithGap,
  Heading,
  IconSize,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React, { FC, useContext, useEffect } from 'react';

import { CloseButtonContainer, QuitContainer } from './styles';
import { QuitModalProps } from './types';

import { DataFeedContext } from '../../context/DataFeedContext';
import { CryptoDefenderLogo } from '../CryptoDefenderLogo';
import { ModalWrapper } from '../MainContainer';

export const QuitModal: FC<QuitModalProps> = ({ onQuit, onCloseModal }) => {
  const { isMobile } = useMatchBreakpoints();
  const { loadAndUpdatePrice } = useContext(DataFeedContext);
  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'scroll';
    };
  }, []);

  const handleQuit = () => {
    loadAndUpdatePrice();
    onQuit();
  };

  return (
    <ModalWrapper>
      <QuitContainer>
        <CloseButtonContainer onClick={onCloseModal}>
          <Close variant={IconSize.L} />
        </CloseButtonContainer>
        <CryptoDefenderLogo size={100} />
        <Heading
          scale={'xxl'}
          color="secondary.white"
          textAlign={isMobile ? 'center' : 'unset'}
        >
          Are you sure you want to leave?
        </Heading>
        <FlexWithGap
          gap={isMobile ? '30px' : '48px'}
          justifyContent="center"
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <Button
            size={IconSize.XL}
            minHeight="unset"
            height={isMobile ? '60px' : '48px'}
            primary
            onClick={onCloseModal}
          >
            No, I will keep defending!
          </Button>
          <Button
            size={IconSize.XL}
            minHeight="unset"
            height="48px"
            secondary
            onClick={handleQuit}
          >
            Yes :(
          </Button>
        </FlexWithGap>
      </QuitContainer>
    </ModalWrapper>
  );
};
