import random from 'random';

import { MAX_PREMIUM, STARTING_PRICE_USD } from '../commons/config';
import { EndPrice, ProtectDetails } from '../interfaces/location';

export interface Result {
  premium: number;
  resultantPrice: number;
  score: number;
}

export const calculatePremiumAndResultantPrice = ({
  floor,
  endPrice,
}: ProtectDetails & EndPrice): Result => {
  const differentiator1 = 2000;
  const differentiator2 = 25;

  if (!endPrice) {
    throw new Error('end price missing');
  }

  const startPrice = parseFloat(STARTING_PRICE_USD);
  // Col O

  const priceDiff = endPrice - startPrice;
  const priceDiffPercent = priceDiff / startPrice;
  console.log('PriceDiff', priceDiffPercent);
  //
  const normalisedEnd = startPrice * (1 + priceDiffPercent);
  console.log('NORMALISED', normalisedEnd);
  const floorN = random.int(1, 5);
  const arbVar = (2 * floorN + 3) / 5;
  console.log('ARB', arbVar, priceDiffPercent);

  // Divide (price difference) by 2 when End Price > Start Price
  const claim = normalisedEnd < (startPrice * floor) / 100;
  console.log('Claim', claim);
  const premium =
    (priceDiffPercent / 1 - 0.5 - 0.01) * -1 * MAX_PREMIUM * arbVar + 0.005;

  // Component 1
  const resultantPrice = claim
    ? (startPrice * floor) / 100 - premium * startPrice
    : normalisedEnd - premium * startPrice;

  const protectionLevel = floor - 100;

  const component2 = protectionLevel - priceDiffPercent * 100;
  const component3 = premium * differentiator1 + differentiator2;

  // 3000 -- Start Price
  const score = claim
    ? 10000 -
      3 * (10000 - (9952 + 227 - (startPrice * component2) / 100 - component3))
    : 10000 -
      2 *
        (10000 -
          (9952 -
            (3000 * 1.5 - resultantPrice) +
            50 * (floor / 95) -
            premium * 300));

  return { premium, resultantPrice, score };
};
