import { Flex, FlexProps } from '@bumper-dao/ui-kit';
import styled from 'styled-components';

import { createSimpleShadowBorder } from '../../../utils/getPilexeledAtributes';

export const ResultsDetailsContainer = styled(Flex)<FlexProps>`
  width: 100%;
  flex-direction: column;
  gap: 20px;
  background-color: ${({ theme }) => theme.colors.background.confirmationBox};
  box-shadow: ${({ theme }) =>
    createSimpleShadowBorder(
      theme.colors.background.confirmationBox,
      4,
      theme.colors.background.confirmationBox,
    )};
  padding: 12px 16px;
`;
