export const createPixelInsetBorder = (
  borderColor: string,
  insetTLColor: string,
  insetBRColor: string,
  borderWidth?: number,
): string => {
  const width = borderWidth ? borderWidth : 2;

  return `${width}px 0px 0px 0px ${insetBRColor}, 
      0px ${width}px 0px 0px ${insetBRColor}, 
      -${width}px 0px 0px 0px ${insetTLColor}, 
      0px -${width}px 0px 0px ${insetTLColor}, 
      -${2 * width}px 0px 0px 0px ${borderColor}, 
      ${2 * width}px 0px 0px 0px ${borderColor}, 
      0px ${2 * width}px 0px 0px ${borderColor}, 
      0px -${2 * width}px 0px 0px ${borderColor}, 
      0px 0px 0px ${width}px ${borderColor}`;
};

export const createSimpleShadowBorder = (
  borderColor: string,
  borderWidth?: number,
  bottomBorderColor?: string,
): string => {
  const width = borderWidth ? borderWidth : 2;

  return `${width}px 0px ${bottomBorderColor ?? borderColor}, 
      0px ${width}px ${bottomBorderColor ?? borderColor}, 
      -${width}px 0px ${borderColor}, 
      0px -${width}px ${borderColor}`;
};
