import {
  Box,
  BoxProps,
  ConfirmationBox,
  ConfirmationBoxProps,
  Grid,
  GridProps,
} from '@bumper-dao/ui-kit';
import styled from 'styled-components';

import {
  createPixelInsetBorder,
  createSimpleShadowBorder,
} from '../../../utils/getPilexeledAtributes';

export const PixelatedField = styled(Box)<BoxProps>`
  max-width: 164px;
  max-height: 28px;
  background-color: ${({ theme }) => theme.colors.primary2.darkSlateGray};
  box-shadow: ${({ theme }) =>
    createSimpleShadowBorder(
      theme.colors.primary2.midnightGreen,
      4,
      theme.colors.primary2.myrtleGreen,
    )};
  padding: 4px 8px;

  ${({ theme }) => theme.mediaQueries.xs} {
    max-width: 144px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 144px;
  }
`;

export const SummaryContainer = styled(Grid)<GridProps>`
  width: 100%;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);

  ${({ theme }) => theme.mediaQueries.sm} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: unset;
  }
`;
export const SummaryWrapper = styled(ConfirmationBox)<ConfirmationBoxProps>`
  background-color: ${({ theme }) => theme.colors.primary2.midnightGreen};
  box-shadow: ${({ theme }) =>
    createPixelInsetBorder(
      theme.colors.primary2.aqua,
      theme.colors.primary2.midnightGreen,
      theme.colors.primary2.midnightGreen,
      4,
    )};
`;
