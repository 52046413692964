import { doc, getDoc, getDocs, setDoc, Timestamp } from 'firebase/firestore';
import { nanoid } from 'nanoid';

import { UserService } from './user.service';

import { NANOID_LEN } from '../commons/config';
import { IGame, IGameId } from '../commons/interfaces/game.interface';
import { gamesCollection } from '../firebase';

export const initialGame: IGame = {
  id: '',
  email: '',
  balance: 0,
  score: 0,
  endPrice: 0,
  term: 0,
  floor: 0,
  protectAmount: 0,
  createdAt: Timestamp.fromDate(new Date()),
  environment: '',
  startPrice: 0,
};

export class GameService {
  public static async getGameById(gameId: IGameId): Promise<IGame | undefined> {
    try {
      const docRef = doc(gamesCollection, gameId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return undefined;
      } else {
        return docSnap.data();
      }
    } catch (err) {
      console.error(`getUserByEmail: ${err}`);
      throw err;
    }
  }

  public static async addGame(
    game: Omit<IGame, IGameId | 'createdAt'>,
  ): Promise<IGameId | void> {
    // eslint-disable-next-line no-useless-catch
    try {
      const payload = {
        ...game,
        id: nanoid(NANOID_LEN),
        createdAt: Timestamp.fromDate(new Date()),
      };
      const gameDocRef = doc(gamesCollection, payload.id);
      await setDoc(gameDocRef, payload);
      return payload.id;
    } catch (err) {
      throw err;
    }
  }

  public static async getAllGames(): Promise<IGame[] | undefined> {
    try {
      const querySnap = await getDocs(gamesCollection);

      if (querySnap.empty) {
        return [];
      } else {
        return querySnap.docs.map((doc) => doc.data());
      }
    } catch (err) {
      console.error(`getUserByEmail: ${err}`);
      throw err;
    }
  }

  public static async getGamesByUserEmail(
    email: string,
  ): Promise<IGame | null> {
    try {
      if (!email) throw new Error('Empty email.');

      const userInfo = await UserService.getUserByEmail(email);

      if (!userInfo?.games || userInfo?.games.length === 0) return null;

      const games = await Promise.all(
        userInfo.games.map((id) => this.getGameById(id)),
      );

      let populatedGame: IGame = initialGame;
      games.forEach((game) => {
        if (game && game.balance > populatedGame.balance) {
          populatedGame = game;
        }
      });
      return populatedGame.balance > 0 ? populatedGame : null;
    } catch (err) {
      console.error(`getLatestGame: ${err}`);
      throw err;
    }
  }
}
