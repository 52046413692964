import { httpsCallable } from 'firebase/functions';

import { FirebaseFunctionNames } from '../commons/functions';
import { firebaseFunctions } from '../firebase';

export interface ParticipantInterface {
  email: string;
  score: number;
}

export class MailerliteService {
  public static async addSubscriber({
    email,
    score,
  }: ParticipantInterface): Promise<void> {
    const sendNotificationFunction = httpsCallable(
      firebaseFunctions,
      FirebaseFunctionNames.SEND_NOTIFICATION,
    );
    sendNotificationFunction({
      email,
      score,
    })
      .then((res) => {
        console.log('Mailerlite Service Response', res);
      })
      .catch((err) => {
        console.log('Mailerlite Service Error', err);
      });
  }
}
