import { Flex, FlexProps } from '@bumper-dao/ui-kit';
import styled from 'styled-components';

interface MainContainerPropsType extends FlexProps {
  width: string;
}
export const MainContainer = styled(Flex)<MainContainerPropsType>`
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: ${({ width }) => width};
`;

export const PixelatedContainer = styled(Flex)`
  width: 100%;
  padding: 36px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.primary2.greenCyprus};
  box-shadow: 4px 0px 0px 0px
      ${({ theme }) => theme.colors.primary2.greenCyprus},
    0px 4px 0px 0px ${({ theme }) => theme.colors.primary2.greenCyprus},
    -4px 0px 0px 0px ${({ theme }) => theme.colors.primary2.greenCyprus},
    0px -4px 0px 0px ${({ theme }) => theme.colors.primary2.greenCyprus},
    -8px 0px 0px 0px ${({ theme }) => theme.colors.primary2.deepTeal},
    8px 0px 0px 0px ${({ theme }) => theme.colors.primary2.deepTeal},
    0px 8px 0px 0px ${({ theme }) => theme.colors.primary2.deepTeal},
    0px -8px 0px 0px ${({ theme }) => theme.colors.primary2.deepTeal},
    0px 0px 0px 4px ${({ theme }) => theme.colors.primary2.deepTeal};
  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 24px;
  }
`;

export const ModalWrapper = styled(Flex)`
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
`;
