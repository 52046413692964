import { Flex } from '@bumper-dao/ui-kit';
import styled, { CSSObject, CSSProperties } from 'styled-components';

import { createPixelInsetBorder } from '../../../utils/getPilexeledAtributes';

export const HighscoreContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-color: ${({ theme }) => theme.colors.primary2.greenCyprus};
  box-shadow: ${({ theme }) =>
    createPixelInsetBorder(
      theme.colors.primary2.deepTeal,
      theme.colors.primary2.greenCyprus,
      theme.colors.primary2.greenCyprus,
      4,
    )};
  padding: 24px;
`;

export const OtpInputStyle = (
  isMobile: boolean,
): Pick<CSSObject, keyof CSSProperties> => ({
  outline: 'none',
  border: 'none',
  borderBottom: '4px solid #EC8F4E',
  backgroundColor: 'inherit',
  width: '60px',
  height: '60px',
  fontFamily: 'Share Tech Mono',
  fontSize: isMobile ? '20px' : '30px',
  lineHeight: '60px',
  letterSpacing: '0.03em',
  textAlign: 'center',
  color: '#FFFFFF',
});

export const OtpInputContainerStyle: Pick<CSSObject, keyof CSSProperties> = {
  display: 'flex',
  gap: '20px',
};
