import { CustomThemeProvider, ResetCSS } from '@bumper-dao/ui-kit';
import React, { FC } from 'react';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
// import { ToastProvider } from 'react-toast-notifications';
// import { ThemeProvider } from 'styled-components';

import { DataFeedProvider } from './components/context/DataFeedContext';
import { RenderRootRoutes } from './components/routes/RenderRoutes';
import { BackgroundImageProvider } from './providers/BackgroundImageProvider';
import { rqClient } from './utils/reactQuery';

const App: FC = () => {
  return (
    <CustomThemeProvider>
      {/* <ThemeProvider theme={light}> */}
      {/* <ToastProvider
          placement="bottom-left"
          autoDismissTimeout={20000}
          autoDismiss={true}
        > */}
      <QueryClientProvider client={rqClient}>
        <DataFeedProvider>
          <BrowserRouter>
            <ResetCSS />
            <BackgroundImageProvider>
              <RenderRootRoutes />
            </BackgroundImageProvider>
          </BrowserRouter>
        </DataFeedProvider>
      </QueryClientProvider>
      {/* </ToastProvider> */}
      {/* </ThemeProvider> */}
    </CustomThemeProvider>
  );
};

export default App;
