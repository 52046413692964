export const Routes = {
  App: '/',
  Dashboard: '/dashboard',
  Details: '/details',
  WalletPage: '/wallet',
  Protect: '/protect',
  Run: '/run',
  Results: '/results',
  HallOfFame: '/hall-of-fame',
};
