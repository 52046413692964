import { StepStatus } from '@bumper-dao/ui-kit';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FlowHeader } from '../components/common/FlowHeader';
import { MainContainer } from '../components/common/MainContainer';
import { QuitModal } from '../components/common/QuitModal/QuitModal';
import { DetailsContent } from '../components/Details/DetailsContent';
import { Routes } from '../components/routes/routes';

export const Details: FC = () => {
  const navigate = useNavigate();
  const stepStatus: StepStatus[] = ['current', 'next', 'next', 'next', 'next'];
  const [openQuit, setOpenQuit] = useState<boolean>(false);

  return (
    <>
      {openQuit && (
        <QuitModal
          onQuit={() => navigate(Routes.Dashboard)}
          onCloseModal={() => setOpenQuit(false)}
        />
      )}
      <MainContainer width={'100%'}>
        <FlowHeader
          stepsStatus={stepStatus}
          cancelClick={() => setOpenQuit(true)}
        />
        <DetailsContent />
      </MainContainer>
    </>
  );
};
