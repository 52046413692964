import { initializeApp } from '@firebase/app';
import {
  collection,
  CollectionReference,
  DocumentData,
  getFirestore,
} from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

import { Collections } from './commons/collections';
import { IGame } from './commons/interfaces/game.interface';
import { IPrice } from './commons/interfaces/price.interface';
import { IUser } from './commons/interfaces/user.interface';

// MAKE SURE THAT ALL ENVS ARE SET
// ESPECIALLY ANALYTICS_MEASUREMENT_ID
const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;

export const firebaseApp = initializeApp(
  {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: `${projectId}.firebaseapp.com`,
    projectId,
    storageBucket: `${projectId}.appspot.com`,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  },
  'DEFAULT',
);

export const firestoreDB = getFirestore(firebaseApp);
// This is just a helper to add the type to the db responses
const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(firestoreDB, collectionName) as CollectionReference<T>;
};

export const firebaseFunctions = getFunctions(firebaseApp);
if (process.env.ENV === 'local')
  connectFunctionsEmulator(firebaseFunctions, 'localhost', 5001);

export const usersCollection = createCollection<IUser>(Collections.USER);
export const gamesCollection = createCollection<IGame>(Collections.GAME);
export const priceCollection = createCollection<IPrice>(Collections.PRICE);
