import { Box, MediumText, SmallText } from '@bumper-dao/ui-kit';
import React, { FC } from 'react';

import { PixelatedField, SummaryContainer, SummaryWrapper } from './styles';
import { ProtectSummaryProps, SummaryFieldProps } from './types';

import { formatStringifyNumberToDot } from '../../../utils/helpers';

export const ProtectSummary: FC<ProtectSummaryProps> = ({
  floorPrice,
  protectedValue,
  currentPrice,
  depositValue,
  disabled,
}) => {
  return (
    <SummaryWrapper disabled={disabled}>
      <SummaryContainer>
        <SummaryField
          title="Deposit Value"
          value={`$${formatStringifyNumberToDot(depositValue, 2)}`}
        />
        <SummaryField
          title="Floor Price"
          value={`$${formatStringifyNumberToDot(floorPrice, 2)}`}
        />
        <SummaryField
          title="Protected Value"
          value={`$${formatStringifyNumberToDot(protectedValue, 2)}`}
        />
        <SummaryField
          title="Current Price"
          value={`$${formatStringifyNumberToDot(currentPrice, 2)}`}
        />
      </SummaryContainer>
    </SummaryWrapper>
  );
};

const SummaryField: FC<SummaryFieldProps> = ({ title, value }) => (
  <Box>
    <SmallText color="primary2.cambridgeBlue" mb="8px">
      {' '}
      {title}{' '}
    </SmallText>
    <PixelatedField>
      <MediumText color="secondary.white"> {value} </MediumText>
    </PixelatedField>
  </Box>
);
