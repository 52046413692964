import {
  Box,
  Button,
  Flex,
  Grid,
  IconSize,
  lightColors,
  SmallText,
} from '@bumper-dao/ui-kit';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import styled from 'styled-components';

import {
  ProtectDetails,
  ProtectLocationProps,
} from '../../interfaces/location';
import { DataFeedContext } from '../context/DataFeedContext';
import { Routes } from '../routes/routes';

interface IPriceProps {
  value: number;
  wait: number;
  oldPrice: number;
  index: number;
}

const Price: React.FC<IPriceProps> = ({ value, wait, index, oldPrice }) => {
  const [hidden, setHidden] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setHidden(false);
    }, wait);
  }, []);

  if (!hidden) {
    const percent = ((value - oldPrice) / oldPrice) * 100;
    const getPercentColor = (per: number): string => {
      if (per > 0) return 'secondary.green';
      if (per < 0) return 'secondary.red';
      return 'secondary.white';
    };
    const formatPercent = (per: number): string => {
      if (per > 0) return `+${percent.toFixed(2)}%`;
      return `${percent.toFixed(2)}%`;
    };

    return (
      <Grid gridTemplateColumns="repeat(2, 1fr)" justifyItems="start">
        <SmallText color="secondary.white">Day {index}:</SmallText>
        <SmallText color={getPercentColor(percent)}>
          {formatPercent(percent)}
        </SmallText>
      </Grid>
    );
  }
  return null;
};

export const PriceSimulator: React.FC<ProtectDetails> = ({ term }) => {
  const navigate = useNavigate();
  const state = useLocation().state as ProtectLocationProps;
  const { simulationDataFeed } = useContext(DataFeedContext);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [showGraphs, setShowGraphs] = useState<boolean>(false);
  const transformedFeed = simulationDataFeed
    .map((x) => ({
      ...x,
      price: +parseFloat(x.price).toFixed(2),
      start: +(
        (parseFloat(simulationDataFeed[0].price) * state.protectDetails.floor) /
        100
      ).toFixed(2),
    }))
    .slice(0, state.protectDetails.term);

  const goToResults = () => {
    const endPrice = parseFloat(
      [
        ...simulationDataFeed
          .slice(0, term + 1)
          .slice(1, simulationDataFeed.length - 1),
      ].pop()?.price ?? '0',
    );

    navigate(Routes.Results, { state: { ...state, endPrice }, replace: true });
  };

  useEffect(() => {
    setTimeout(() => {
      setShowResults(true);
    }, 100 * state.protectDetails.term + 1000);
    setTimeout(() => {
      setShowGraphs(true);
    }, 500);
  }, []);

  return (
    <Box width="100%">
      <RunContainer>
        <div style={{ paddingBottom: '24px' }}>
          <p
            style={{
              textAlign: 'left',
              fontSize: '28px',
              color: lightColors.typography.white,
            }}
          >
            $ Price Feed
          </p>
        </div>
        <Flex>
          <PricesWrapper>
            <PricesContainer>
              {simulationDataFeed
                .slice(0, term + 1)
                .slice(1, simulationDataFeed.length)
                .map(({ price }, index) => {
                  return (
                    <Price
                      index={index + 1}
                      key={price}
                      value={parseFloat(price)}
                      wait={100 * index}
                      oldPrice={parseFloat(simulationDataFeed[index].price)}
                    />
                  );
                })}
            </PricesContainer>
          </PricesWrapper>
          {simulationDataFeed.length > 0 && showGraphs && (
            <Flex width="100%" px="15px" paddingTop={20}>
              <ResponsiveContainer>
                <LineChart width={475} height={400} data={transformedFeed}>
                  <Line
                    type="linear"
                    dataKey={'price'}
                    stroke="#60C770"
                    strokeWidth={4}
                    animationDuration={100 * state.protectDetails.term}
                    dot={false}
                  ></Line>
                  <Line
                    strokeWidth={4}
                    type="monotone"
                    dataKey={'start'}
                    strokeDasharray="4 3"
                    stroke="#FFFFFF"
                    dot={false}
                    isAnimationActive={false}
                  ></Line>
                  <CartesianGrid stroke="#ccc" strokeDasharray="2 2" />
                  <XAxis stroke="#FFFFFF" interval={5} />
                  <YAxis
                    width={80}
                    stroke="#FFFFFF"
                    type="number"
                    domain={[
                      +(
                        Math.min(
                          (parseFloat(simulationDataFeed[0].price) *
                            state.protectDetails.floor) /
                            100,
                          Math.min(...transformedFeed.map((x) => x.price)),
                        ) - 100
                      ).toFixed(2),
                      +(
                        Math.max(...transformedFeed.map((x) => x.price)) + 100
                      ).toFixed(2),
                    ]}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Flex>
          )}
        </Flex>
      </RunContainer>
      <div style={{ paddingTop: '50px' }}>
        {showResults && (
          <Button
            size={IconSize.XL}
            height="48px"
            width="100%"
            minHeight="unset"
            primary
            onClick={goToResults}
          >
            Go to Results
          </Button>
        )}
      </div>
    </Box>
  );
};

const RunContainer = styled(Box)`
  width: 100%;
  padding: 16px 13px;
  font-family: Share Tech Mono;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary.green};
  background-color: ${({ theme }) => theme.colors.primary2.greenCyprus};

  box-shadow: 4px 0px 0px 0px
      ${({ theme }) => theme.colors.primary2.greenCyprus},
    0px 4px 0px 0px ${({ theme }) => theme.colors.primary2.greenCyprus},
    -4px 0px 0px 0px ${({ theme }) => theme.colors.primary2.greenCyprus},
    0px -4px 0px 0px ${({ theme }) => theme.colors.primary2.greenCyprus},
    -8px 0px 0px 0px ${({ theme }) => theme.colors.primary2.deepTeal},
    8px 0px 0px 0px ${({ theme }) => theme.colors.primary2.deepTeal},
    0px 8px 0px 0px ${({ theme }) => theme.colors.primary2.deepTeal},
    0px -8px 0px 0px ${({ theme }) => theme.colors.primary2.deepTeal},
    0px 0px 0px 4px ${({ theme }) => theme.colors.primary2.deepTeal};

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 16px 24px;
  }
`;

const PricesWrapper = styled(Flex)`
  padding: 12px 12px 12px 16px;
  height: 479px;
  width: 200px;
  background-color: ${({ theme }) => theme.colors.primary2.darkSlateGray};
`;
const PricesContainer = styled(Flex)`
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
`;
