import { Flex } from '@bumper-dao/ui-kit';
import bumperLogo from '@bumper-dao/ui-kit/dist/images/32px/largeBumperLogo.svg';
import React, { FC } from 'react';
import styled from 'styled-components';

import { HeaderFooter } from '../common/HeaderFooter';

export const DashboardHeader: FC = () => {
  return (
    <HeaderWrapper>
      <BumperLogoWrapper>
        <img src={bumperLogo} alt="logo" />
      </BumperLogoWrapper>
      <HeaderFooter />
    </HeaderWrapper>
  );
};

const BumperLogoWrapper = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary2.cyprus};
`;

const HeaderWrapper = styled(Flex)`
  width: 100%;
  height: fit-content;
  flex-direction: column;
  border-bottom: 4ps solid ${({ theme }) => theme.colors.primary2.deepTeal};
`;
