import random from 'random';
import React, { useEffect, useState } from 'react';

import data from '../../commons/chainlink-data-v2.json';
import { STARTING_PRICE_USD } from '../../commons/config';
import { IPrice } from '../../commons/interfaces/price.interface';
// import { PriceService } from '../../services/price.service';

export const GLOBAL_DATA_FEED_INDEX_START = 0;
// End will be changed.
export const GLOBAL_DATA_FEED_INDEX_END = 5000;

export interface IDataFeedContext {
  simulationDataFeed: IPrice[];
  historyDataFeed: IPrice[];
  loadAndUpdatePrice: () => void;
}

export const DataFeedContext = React.createContext<IDataFeedContext>({
  simulationDataFeed: [],
  historyDataFeed: [],
  loadAndUpdatePrice: () => {
    console.log('Load function initial');
  },
});

export const DataFeedProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [simulationDataFeed, setSimulationDataFeed] = useState<IPrice[]>([]);
  const [historyDataFeed, setHistoryPriceFeed] = useState<IPrice[]>([]);

  const loadAndUpdatePrice = () => {
    const startIndex = random.int(
      GLOBAL_DATA_FEED_INDEX_START,
      GLOBAL_DATA_FEED_INDEX_END,
    );

    let currentDate = new Date(data[startIndex - 1].date);
    const resultData = [];
    for (let i = startIndex; i < data.length; i++) {
      if (i === startIndex) {
        resultData.push(data[i]);
      } else if (resultData.length < 100) {
        const nowDate = new Date(data[i].date);
        const diff =
          (nowDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24);

        if (Math.floor(diff) === 1) {
          currentDate = nowDate;
          resultData.push(data[i]);
        }
      }
    }

    const simData = resultData.slice(0, 97);
    const volatilityArrForSimulator = simData
      .slice(1, simData.length)
      .map(
        ({ price }, index) =>
          ((parseFloat(price) - parseFloat(simData[index].price)) /
            parseFloat(simData[index].price)) *
          1,
      );
    volatilityArrForSimulator[0] = 0;

    const pricearr: number[] = [];

    for (let i = 0; i < volatilityArrForSimulator.length; i++) {
      if (i === 0) {
        pricearr.push(parseFloat(STARTING_PRICE_USD));
      } else {
        const oldPrice = pricearr[i - 1];
        const val = oldPrice + oldPrice * volatilityArrForSimulator[i];
        pricearr.push(val);
      }
    }

    setHistoryPriceFeed(
      simData.slice(0, 7).map((item, index) => ({
        ...item,
        price: pricearr[index].toFixed(4),
      })),
    );

    setSimulationDataFeed(
      simData.slice(6, 97).map((item, index) => ({
        ...item,
        price: pricearr[index + 5].toFixed(4),
      })),
    );
    //   },
    // );
  };

  useEffect(() => {
    loadAndUpdatePrice();
  }, []);

  return (
    <DataFeedContext.Provider
      value={{ simulationDataFeed, historyDataFeed, loadAndUpdatePrice }}
    >
      {children}
    </DataFeedContext.Provider>
  );
};
