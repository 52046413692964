import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { AppRouteComponent } from './AppRouteComponent';
import { Routes } from './routes';

import { Dashboard } from '../../pages/Dashboard';
import { Details } from '../../pages/Details';
import { HallOfFame } from '../../pages/HallOfFame';
import { Protect } from '../../pages/Protect';
import { Results } from '../../pages/Results';
import { Run } from '../../pages/Run';
import { WalletPage } from '../../pages/WalletPage';

export const routesConfig: RouteObject[] = [
  {
    path: '',
    element: <Navigate to={Routes.Dashboard} replace />,
    caseSensitive: true,
  },
  {
    path: Routes.App,
    element: <AppRouteComponent />,
    children: [
      {
        path: Routes.Dashboard,
        element: <Dashboard />,
      },
      {
        path: Routes.Details,
        element: <Details />,
      },
      {
        path: Routes.WalletPage,
        element: <WalletPage />,
      },
      {
        path: Routes.Protect,
        element: <Protect />,
      },
      {
        path: Routes.Results,
        element: <Results />,
      },
      {
        path: Routes.HallOfFame,
        element: <HallOfFame />,
      },
      {
        path: `${Routes.HallOfFame}/:id`,
        element: <HallOfFame />,
      },
      {
        path: Routes.Run,
        element: <Run />,
      },
      {
        path: '*',
        element: <Navigate to={Routes.Dashboard} replace />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={Routes.Dashboard} replace />,
  },
];
