import {
  Flex,
  Heading,
  Step,
  Stepper,
  useMatchBreakpoints,
  Close,
  IconSize,
  lightColors,
  Box,
} from '@bumper-dao/ui-kit';
import React, { FC } from 'react';

import {
  FlowHeaderContainer,
  FlowHeaderCenter,
  MobileCryptoDefenderLogo,
} from './styles';
import { FlowHeaderProps } from './types';

import { CryptoDefenderLogo } from '../CryptoDefenderLogo';

const FlowHeader: FC<FlowHeaderProps> = ({ stepsStatus, cancelClick }) => {
  const { isMobile } = useMatchBreakpoints();
  const steps = ['Details', 'Wallet', 'Protect', 'Run', 'Results'];
  return (
    <FlowHeaderContainer
      pb={isMobile && !stepsStatus ? '0 !important' : '32px'}
    >
      <Flex
        alignItems="center"
        justifyContent={isMobile ? 'end' : 'space-between'}
        width="100%"
        height={isMobile ? '90px' : 'unset'}
        position="relative"
      >
        {!isMobile && <CryptoDefenderLogo size={56} />}
        {isMobile && <MobileCryptoDefenderLogo size={90} />}
        {!isMobile && !!stepsStatus && (
          <FlowHeaderCenter>
            <Stepper>
              {steps.map((step, index) => (
                <Step key={step} index={index} status={stepsStatus[index]}>
                  {step}
                </Step>
              ))}
            </Stepper>
          </FlowHeaderCenter>
        )}
        <Flex
          alignItems="center"
          onClick={cancelClick}
          style={{ cursor: 'pointer' }}
        >
          {!isMobile && (
            <Heading mr="12px" color={lightColors.typography.input}>
              Quit
            </Heading>
          )}
          <Close variant={IconSize.L} />
        </Flex>
      </Flex>
      {isMobile && !!stepsStatus && (
        <Box style={{ transform: 'scale(0.9)' }}>
          <Stepper>
            {steps.map((step, index) => (
              <Step
                key={step}
                index={index}
                status={stepsStatus[index]}
                scale="sm"
              >
                {step}
              </Step>
            ))}
          </Stepper>
        </Box>
      )}
    </FlowHeaderContainer>
  );
};

export default FlowHeader;
