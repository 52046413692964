import { StepStatus } from '@bumper-dao/ui-kit';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FlowHeader } from '../components/common/FlowHeader';
import { MainContainer } from '../components/common/MainContainer';
import { QuitModal } from '../components/common/QuitModal/QuitModal';
import { ResultsContent } from '../components/Results/ResultsContent';
import { Routes } from '../components/routes/routes';

export const Results: FC = () => {
  const navigate = useNavigate();
  const stepStatus: StepStatus[] = ['past', 'past', 'past', 'past', 'current'];
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      {open && (
        <QuitModal
          onQuit={() => navigate(Routes.Dashboard)}
          onCloseModal={() => setOpen(false)}
        />
      )}
      <MainContainer width={'100%'}>
        <FlowHeader
          stepsStatus={stepStatus}
          cancelClick={() => setOpen(true)}
        />
        <ResultsContent />
      </MainContainer>
    </>
  );
};
