import {
  Box,
  Button,
  Flex,
  FlexWithGap,
  Grid,
  Heading,
  IconSize,
  useMatchBreakpoints,
  LargeText,
} from '@bumper-dao/ui-kit';
import { onSnapshot } from 'firebase/firestore';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import styled from 'styled-components';

import { GamerFameDataType } from './types';

import homeIcon from '../../assets/icons/homeIcon.png';
import { gamesCollection } from '../../firebase';
import { createSimpleShadowBorder } from '../../utils/getPilexeledAtributes';
import { formatStringifyNumberToDot } from '../../utils/helpers';
import {
  getTwitterLink,
  getTelegramLink,
  getWhatsappLink,
} from '../../utils/socialLinks';
import { MainContainer, PixelatedContainer } from '../common/MainContainer';
import { DataFeedContext } from '../context/DataFeedContext';
import { Routes } from '../routes/routes';

export interface HallOfFameLocationProps {
  gameId: string;
  score: number;
  initials: string;
}

export const HallOfFameContent: FC = () => {
  const navigate = useNavigate();
  // state is only observed when the user
  // is landing on this page from the Results Page
  const state = useLocation().state as HallOfFameLocationProps;
  const { loadAndUpdatePrice } = useContext(DataFeedContext);
  const { isMobile } = useMatchBreakpoints();
  const [leadersData, setLeadersData] = useState<GamerFameDataType[]>([]);
  const shouldShowShareButtons =
    state?.gameId && process.env.REACT_APP_ENV === 'PUBLIC';

  const onHomeClick = () => {
    loadAndUpdatePrice();
    navigate(Routes.Dashboard);
  };

  // Runs on mount
  useEffect(() => {
    const unSub = onSnapshot(gamesCollection, (snapshot) => {
      if (snapshot.empty) {
        return;
      }

      const sortedData = snapshot.docs
        .map((doc) => doc.data())
        .sort((a, b) => b?.score - a?.score);

      const data = sortedData.slice(0, 10).map((doc, index) => ({
        place: index + 1,
        score: doc?.score.toFixed(2),
        initials: doc?.initials ?? '- - -',
        ...(state?.gameId && state.gameId === doc.id
          ? { currentUser: true }
          : {}),
      }));

      if (state) {
        const isCurrentUserInList = data.find((el) => el.currentUser);

        if (!isCurrentUserInList) {
          const x = sortedData.filter((x) => x.score > state.score);
          data.push({
            place: x.length,
            score: state.score.toFixed(2),
            initials: state.initials,
            currentUser: true,
          });
        }
      }

      setLeadersData(data as GamerFameDataType[]);
    });

    return unSub;
  }, []);

  return (
    <MainContainer
      width={isMobile ? '90%' : '708px'}
      py="60px"
      style={{ gap: '40px' }}
    >
      <PixelatedContainer
        alignItems="center"
        p={`56px ${isMobile ? '24px' : '154px'} !important`}
      >
        <Title scale={'xxl'}>HALL OF FAME</Title>
        <PlayersContainer mt="27px">
          {leadersData.map((leader) => (
            <FameRow key={leader.place} {...leader} />
          ))}
        </PlayersContainer>

        {shouldShowShareButtons ? (
          <Box>
            <LargeText
              color="secondary.white"
              py={'20px'}
              fontSize={'20px'}
              textAlign="center"
            >
              Share your score and challenge your friends on:
            </LargeText>
            <FlexWithGap
              gap="20px"
              alignItems="center"
              justifyContent={'center'}
              width={'100%'}
            >
              <a
                href={getTwitterLink(state.score.toFixed(2))}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  size={IconSize.L}
                  secondary
                  minHeight="unset"
                  height="48px"
                >
                  <SocialIcon
                    network="twitter"
                    bgColor="transparent"
                    fgColor="white"
                    style={{ height: 36, width: 36 }}
                  />
                </Button>
              </a>
              <a
                href={getTelegramLink(state.score.toFixed(2))}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  size={IconSize.L}
                  secondary
                  minHeight="unset"
                  height="48px"
                  // mt="56px"
                >
                  <SocialIcon
                    network="telegram"
                    bgColor="transparent"
                    fgColor="white"
                    style={{ height: 36, width: 36 }}
                  />
                </Button>
              </a>{' '}
              <a
                href={getWhatsappLink(state.score.toFixed(2))}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  size={IconSize.L}
                  secondary
                  minHeight="unset"
                  height="48px"
                  // mt="56px"
                >
                  <SocialIcon
                    network="whatsapp"
                    bgColor="transparent"
                    fgColor="white"
                    style={{ height: 48, width: 48 }}
                  />
                </Button>
              </a>
            </FlexWithGap>
          </Box>
        ) : null}
      </PixelatedContainer>
      <Button
        size={IconSize.XL}
        primary
        minHeight="unset"
        height="48px"
        width="100%"
        mt="20px"
        onClick={onHomeClick}
      >
        <FlexWithGap gap="14px" alignItems="center">
          <img src={homeIcon} width="32px" />
          <Heading scale={'xl'} color="secondary.white">
            Home
          </Heading>
        </FlexWithGap>
      </Button>
    </MainContainer>
  );
};

const FameRow: FC<GamerFameDataType> = ({
  place,
  initials,
  currentUser,
  score,
}) => {
  const { isMobile } = useMatchBreakpoints();
  const defaultColor = 'secondary.white';
  const getPlaceColor = (p: number): string => {
    if (p <= 3 && p >= 1 && !currentUser) return 'primary1.vividTangelo';
    if (currentUser) return 'secondary.green';
    return defaultColor;
  };

  return (
    <Grid gridTemplateColumns="1fr 1fr 3fr" justifyItems="center" width="100%">
      <Flex width="100%" justifyContent="start">
        <Heading scale={isMobile ? 'xl' : 'xxl'} color={getPlaceColor(place)}>
          {place}.
        </Heading>
      </Flex>
      <Flex width="100%" justifyContent="start" alignItems={'flex-end'}>
        <Heading scale={isMobile ? 'xl' : 'xxl'} color={getPlaceColor(place)}>
          {initials}
        </Heading>
        <Heading scale={isMobile ? 'sm' : 'md'} color={getPlaceColor(place)}>
          {currentUser ? '(YOU)' : null}
        </Heading>
      </Flex>
      <Flex width="100%" justifyContent="end">
        <Heading scale={isMobile ? 'xl' : 'xxl'} color={defaultColor}>
          {formatStringifyNumberToDot(score, 2)}
        </Heading>
      </Flex>
    </Grid>
  );
};

const Title = styled(Heading)`
  box-shadow: ${({ theme }) =>
    createSimpleShadowBorder(
      theme.colors.primary1.vividTangelo,
      4,
      theme.colors.primary1.vividTangelo,
    )};
  color: ${({ theme }) => theme.colors.secondary.white};
  padding: 8px 24px;
`;

const PlayersContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: 8px;
`;
