import { Box, Flex } from '@bumper-dao/ui-kit';
import styled from 'styled-components';

import { CryptoDefenderLogo } from '../CryptoDefenderLogo';

export const FlowHeaderCenter = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const FlowHeaderContainer = styled(Flex)`
  width: 100%;
  height: 100px;
  align-items: center;
  position: relative;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.primary2.cyprus};
  padding: 0 36px;
  box-sizing: border-box;
  border-bottom: 4px solid ${({ theme }) => theme.colors.primary2.darkGreen};
  ${({ theme }) => theme.mediaQueries.sm} {
    height: unset;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 32px;
  }
`;

export const MobileCryptoDefenderLogo = styled(CryptoDefenderLogo)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
