import { Flex } from '@bumper-dao/ui-kit';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { HeaderFooter } from '../common/HeaderFooter';

export const AppRouteComponent: React.FC = React.memo(() => {
  return (
    <Flex flexDirection="column" minHeight="100vh">
      <Outlet />
      <HeaderFooter />
    </Flex>
  );
});
