import React, { FC } from 'react';

import { MainContainer } from '../components/common/MainContainer';
import { DashboardContent } from '../components/Dashboard/DashboardContent';
import { DashboardHeader } from '../components/Dashboard/DashboardHeader';

export const Dashboard: FC = () => {
  return (
    <MainContainer width={'100%'}>
      <DashboardHeader />
      <DashboardContent />
    </MainContainer>
  );
};
