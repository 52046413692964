import {
  Box,
  ConfirmationBox,
  Flex,
  Text,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React from 'react';
import styled from 'styled-components';

import { ConfirmCardProps } from './types';

const HorizontalDivider = styled(Box)`
  height: 4px;
  background-color: ${({ theme }) => theme.colors.primary2.aqua};
`;

export const ResultDetails: React.FC<ConfirmCardProps> = ({
  iconComponent,
  title,
  children,
}) => {
  const { isMobile } = useMatchBreakpoints();
  return (
    <ConfirmationBox
      flexDirection="column"
      position="relative"
      px={isMobile ? '12px' : '106px'}
      pb="40px"
      pt="50px"
    >
      {iconComponent}
      <Flex alignItems="center" justifyContent="center" mt="16px">
        <Text
          fontFamily="Share Tech Mono"
          fontSize={isMobile ? '20px' : '32px'}
          lineHeight="48px"
          color="secondary.white"
        >
          {title}
        </Text>
      </Flex>
      <HorizontalDivider mt={isMobile ? '24px' : '32px'} mb="20px" />
      {children}
    </ConfirmationBox>
  );
};
