import { StepStatus } from '@bumper-dao/ui-kit';
import React, { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FlowHeader } from '../components/common/FlowHeader';
import { MainContainer } from '../components/common/MainContainer';
import { QuitModal } from '../components/common/QuitModal/QuitModal';
import { Routes } from '../components/routes/routes';
import { WalletPageContent } from '../components/WalletPage/WalletPageContent';
import { DefaultLocationProps } from '../interfaces/location';

export const WalletPage: FC = () => {
  const navigate = useNavigate();
  const state = useLocation().state as DefaultLocationProps;
  const stepStatus: StepStatus[] = ['past', 'current', 'next', 'next', 'next'];
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      {open && (
        <QuitModal
          onQuit={() => navigate(Routes.Dashboard)}
          onCloseModal={() => setOpen(false)}
        />
      )}
      <MainContainer width={'100%'}>
        <FlowHeader
          stepsStatus={stepStatus}
          cancelClick={() => setOpen(true)}
        />
        <WalletPageContent {...state} />
      </MainContainer>
    </>
  );
};
