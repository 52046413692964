import { Heading, useMatchBreakpoints } from '@bumper-dao/ui-kit';
import React, { FC } from 'react';
import OtpInput from 'react-otp-input';

import {
  HighscoreContainer,
  OtpInputContainerStyle,
  OtpInputStyle,
} from './style';

export interface IScore {
  score: number;
  onInitialChange: (arg: string) => void;
  initials: string;
}

export const Highscore: FC<IScore> = ({ score, initials, onInitialChange }) => {
  const { isMobile, isTablet } = useMatchBreakpoints();
  return (
    <HighscoreContainer mt="40px">
      <Heading scale={'xxl'} color="secondary.green">
        Your Score: {score.toFixed(4)}
      </Heading>
      <Heading scale={'lg'} color="typography.input">
        Enter Your Initials:
      </Heading>
      <OtpInput
        value={initials}
        onChange={(otp: string) => onInitialChange(otp.toUpperCase())}
        numInputs={3}
        inputStyle={OtpInputStyle(isTablet || isMobile)}
        containerStyle={OtpInputContainerStyle}
      />
    </HighscoreContainer>
  );
};
