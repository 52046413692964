import {
  Box,
  Button,
  Flex,
  FlexWithGap,
  IconSize,
  LargeText,
  lightColors,
  MediumText,
  SmallText,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { Highscore } from './Highscore/Highscore';
import { ResultDetails } from './ResultDetails/ResultDetails';
import { ResultsDetailsContainer } from './ResultDetails/styles';

import ConfLogo from '../../assets/icons/ResultLogo.svg';
import { STARTING_BALANCE_ETH, STARTING_PRICE_USD } from '../../commons/config';
import { IGame, IGameId } from '../../commons/interfaces/game.interface';
import { EndPrice, ProtectLocationProps } from '../../interfaces/location';
import { GameService } from '../../services/game.service';
import { MailerliteService } from '../../services/mailerlite.service';
import { UserService } from '../../services/user.service';
import { formatStringifyNumberToDot } from '../../utils/helpers';
import { calculatePremiumAndResultantPrice, Result } from '../../utils/premium';
import { MainContainer } from '../common/MainContainer';
import { DataFeedContext } from '../context/DataFeedContext';
import { Routes } from '../routes/routes';

export const ResultsContent: FC = () => {
  const navigate = useNavigate();
  const { isMobile } = useMatchBreakpoints();
  const { email, protectDetails, endPrice } = useLocation()
    .state as ProtectLocationProps & EndPrice;
  const { simulationDataFeed } = useContext(DataFeedContext);

  const [result, setResult] = useState<Result>({
    score: 0,
    premium: 0,
    resultantPrice: 0,
  });

  const [initials, setInitials] = useState<string>('');

  const { isLoading: isSaveGameLoading, mutateAsync: saveGameMutate } =
    useMutation(
      'save-game',
      (data: Omit<IGame, IGameId | 'createdAt'>) => {
        return GameService.addGame(data);
      },
      {
        onSuccess: (data) => {
          navigate(Routes.HallOfFame, {
            state: { gameId: data, score: result.score, initials },
            replace: true,
          });
        },
      },
    );

  const onInitialChange = (val: string) => {
    setInitials(val);
  };

  // console.log(
  //   'Calc',
  //   calculatePremiumAndResultantPrice({
  //     endPrice: 1800,
  //     term: 90,
  //     floor: 80,
  //     protectAmount: 1,
  //   }),
  // );

  const handleSaveGame = async () => {
    const game = {
      email,
      endPrice,
      balance: result.resultantPrice,
      environment: process.env.REACT_APP_ENV ?? 'DEV',
      score: result.score,
      initials,
      startPrice: parseFloat(simulationDataFeed[0]?.price),
      ...protectDetails,
    };

    const user = await UserService.getUserByEmail(email);

    if (!user?.notificationSent) {
      await MailerliteService.addSubscriber({ email, score: result.score });
    }

    // Asynchronously call the update to avoid delay
    UserService.updateUser(email, {
      notificationSent: true,
    }).catch((err) => {
      console.error(`handleSaveGame -> UserService.updateUser`, err);
    });

    saveGameMutate(game);
  };

  useEffect(() => {
    if (endPrice) {
      setResult(
        calculatePremiumAndResultantPrice({
          endPrice,
          ...protectDetails,
        }),
      );
    }
  }, [endPrice]);

  return (
    <MainContainer width={isMobile ? '90%' : '708px'} py="40px">
      <Box paddingBottom={'60px'}>
        <p
          style={{
            fontFamily: 'Share Tech Mono',
            fontSize: '36px',
            color: lightColors.typography.white,
          }}
        >
          Results
        </p>
      </Box>
      <ResultDetails
        iconComponent={
          <img
            src={ConfLogo}
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              top: '-28px',
            }}
            width="56px"
            height="49px"
            alt="Icon"
          />
        }
        title={`${new Date().toDateString()} ${new Date().toLocaleTimeString()}`}
      >
        <FlexWithGap flexDirection="column" gap="20px">
          <Flex width="100%" alignItems="center" justifyContent="space-between">
            <LargeText
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
              bold
            >
              Start ETH Price:
            </LargeText>
            <LargeText
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
            >
              ${formatStringifyNumberToDot(simulationDataFeed[0]?.price, 2)}
            </LargeText>
          </Flex>
          <Flex width="100%" alignItems="center" justifyContent="space-between">
            <LargeText
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
              bold
            >
              Start Value Of Wallet:
            </LargeText>
            <LargeText
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
            >
              $
              {formatStringifyNumberToDot(
                (
                  parseFloat(STARTING_BALANCE_ETH) *
                  parseFloat(simulationDataFeed[0]?.price)
                ).toString(),
                2,
              )}
            </LargeText>
          </Flex>
          <Box width="100%" p="4px">
            <ResultsDetailsContainer>
              <Flex
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <MediumText color="secondary.white" bold>
                  Premium:
                </MediumText>
                <MediumText color="secondary.white">
                  {(result.premium * 100).toFixed(2)}%
                </MediumText>
              </Flex>
              <Flex
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <MediumText color="secondary.white" bold>
                  Floor:
                </MediumText>
                <MediumText color="secondary.white">
                  {protectDetails.floor} %
                </MediumText>
              </Flex>
              <Flex
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <MediumText color="secondary.white" bold>
                  Protection Term:
                </MediumText>
                <MediumText color="secondary.white">
                  {protectDetails.term} days
                </MediumText>
              </Flex>
              <Flex
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <MediumText color="secondary.white" bold>
                  Final ETH Price:
                </MediumText>
                <Flex alignItems={'center'}>
                  <MediumText color="secondary.white">
                    {' '}
                    ${endPrice?.toFixed(2)}
                  </MediumText>

                  {endPrice && (
                    <SmallText color="secondary.white" paddingLeft={'5px'}>
                      (
                      {(
                        ((endPrice - parseFloat(STARTING_PRICE_USD)) /
                          parseFloat(STARTING_PRICE_USD)) *
                        100
                      ).toFixed(2)}
                      %)
                    </SmallText>
                  )}
                </Flex>
              </Flex>

              {endPrice && (
                <Flex
                  width="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <MediumText color="secondary.white" bold>
                    Final Value Of Wallet:
                  </MediumText>
                  <MediumText color="secondary.white">
                    $
                    {(
                      result.resultantPrice * parseFloat(STARTING_BALANCE_ETH) -
                      result.premium * 3000
                    ).toFixed(2)}
                    {/* {(
                      (protectDetails.floor / 100) *
                        parseFloat(simulationDataFeed[0]?.price) *
                        parseFloat(STARTING_BALANCE_ETH) -
                      result.premium * 3000
                    ).toFixed(2)} */}
                  </MediumText>
                </Flex>
              )}

              {endPrice && (
                <Flex
                  width="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <MediumText color="secondary.white" bold>
                    Final Value Without BUMPER:
                  </MediumText>
                  <MediumText color="secondary.white">
                    ${(endPrice * parseFloat(STARTING_BALANCE_ETH)).toFixed(2)}
                  </MediumText>
                </Flex>
              )}
            </ResultsDetailsContainer>
          </Box>
        </FlexWithGap>
      </ResultDetails>
      <Highscore
        score={result.score}
        initials={initials}
        onInitialChange={onInitialChange}
      />
      <Button
        size={IconSize.XL}
        width="100%"
        height="48px"
        minHeight="unset"
        mt="40px"
        primary
        onClick={handleSaveGame}
        disabled={!initials || isSaveGameLoading}
      >
        Hall of Fame
      </Button>
    </MainContainer>
  );
};
