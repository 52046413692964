import {
  Box,
  Close,
  Heading,
  IconSize,
  LargeText,
  SmallText,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React, { FC } from 'react';

import { CloseButtonContainer, Container } from './styles';
import { PrivacyModalProps } from './types';

import { CryptoDefenderLogo } from '../CryptoDefenderLogo';
import { ModalWrapper } from '../MainContainer';

export const PrivacyModal: FC<PrivacyModalProps> = ({ onCloseModal }) => {
  const { isMobile } = useMatchBreakpoints();

  return (
    <ModalWrapper>
      <Container>
        <CloseButtonContainer onClick={onCloseModal}>
          <Close variant={IconSize.L} />
        </CloseButtonContainer>

        <Box paddingY={'20px'}>
          <CryptoDefenderLogo size={isMobile ? 100 : 50} />
        </Box>

        <Box
          height={'100%'}
          overflowY="auto"
          paddingX={'10px'}
          paddingY={'20px'}
        >
          <Heading
            scale={'xl'}
            color="secondary.white"
            width={'100%'}
            paddingBottom="20px"
            textAlign={isMobile ? 'center' : 'center'}
          >
            Privacy Policy
          </Heading>
          <SmallText color="white">
            At Bumper, accessible from https://bumper.fi, one of our main
            priorities is the privacy of our visitors. This Privacy Policy
            document contains types of information that is collected and
            recorded by Bumper and how we use it.
          </SmallText>
          <SmallText color="white">
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </SmallText>

          <LargeText py={15} fontWeight="bold" color="white">
            General Data Protection Regulation (GDPR)
          </LargeText>
          <SmallText color="white">
            We are a Data Controller of your information.
          </SmallText>
          <SmallText color="white">
            Bumper legal basis for collecting and using the personal information
            described in this Privacy Policy depends on the Personal Information
            we collect and the specific context in which we collect the
            information:
          </SmallText>
          <SmallText>
            <ul
              style={{
                fontFamily: 'Roboto Mono',
                color: 'white',
                fontSize: '12px',
                padding: '1em 1em',
              }}
            >
              <li>Bumper needs to perform a contract with you</li>
              <li>You have given Bumper permission to do so</li>
              <li>
                Processing your personal information is in Bumper legitimate
                interests
              </li>
              <li>Bumper needs to comply with the law</li>
            </ul>
          </SmallText>
          <SmallText color="white">
            Bumper will retain your personal information only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use your information to the extent necessary to comply
            with our legal obligations, resolve disputes, and enforce our
            policies.
          </SmallText>
          <SmallText color="white">
            If you are a resident of the European Economic Area (EEA), you have
            certain data protection rights. If you wish to be informed what
            Personal Information we hold about you and if you want it to be
            removed from our systems, please contact us.
          </SmallText>
          <SmallText color="white">
            In certain circumstances, you have the following data protection
            rights:
          </SmallText>
          <SmallText color="white" p={10}>
            <ul>
              <li>
                The right to access, update or to delete the information we have
                on you.
              </li>
              <li>The right of rectification.</li>
              <li>The right to object.</li>
              <li>The right of restriction.</li>
              <li>The right to data portability</li>
              <li>The right to withdraw consent</li>
            </ul>
          </SmallText>
          <LargeText py={15} fontWeight="bold" color="white">
            Log Files
          </LargeText>
          <SmallText color="white">
            {
              "Bumper follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information."
            }
          </SmallText>
          <LargeText py={15} fontWeight="bold" color="white">
            Cookies and Web Beacons
          </LargeText>
          <SmallText color="white">
            {
              "Like any other website, Bumper uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information."
            }
            <br></br>
            <br></br>
            {
              "For more general information on cookies, please read 'What Are Cookies'."
            }
          </SmallText>
          <LargeText py={15} fontWeight="bold" color="white">
            Privacy Policies
          </LargeText>
          <SmallText py={15} fontWeight="bold" color="white">
            {
              'You may consult this list to find the Privacy Policy for each of the advertising partners of Bumper.'
            }

            {
              "Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Bumper, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit."
            }

            {
              'Note that Bumper has no access to or control over these cookies that are used by third-party advertisers.'
            }
          </SmallText>
          <LargeText color="white" py={15} fontWeight="bold">
            Third Party Privacy Policies
          </LargeText>
          <SmallText color="white">
            {
              "Bumper's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options."
            }
          </SmallText>
          <SmallText color="white">
            $
            {
              "You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites."
            }
          </SmallText>
          <LargeText py={15} fontWeight="bold" color="white">
            {"Children's Information"}
          </LargeText>
          <SmallText color="white">
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </SmallText>
          <SmallText color="white">
            Bumper does not knowingly collect any Personal Identifiable
            Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best efforts to promptly remove such information from our records.
          </SmallText>
          <LargeText py={15} fontWeight="bold" color="white">
            Online Privacy Policy Only
          </LargeText>
          <SmallText color="white">
            Our Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in Bumper. This policy is not
            applicable to any information collected offline or via channels
            other than this website.
          </SmallText>
          <LargeText py={15} fontWeight="bold" color="white">
            Consent
          </LargeText>
          <SmallText color="white">
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms.
          </SmallText>
          <br></br>
          <SmallText color="white">
            Our Privacy Policy was generated with the help of GDPR Privacy
            Policy Generator from GDPRPrivacyNotice.com
          </SmallText>
        </Box>
      </Container>
    </ModalWrapper>
  );
};
