import { Box, useMatchBreakpoints } from '@bumper-dao/ui-kit';
import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import qrcode from '../assets/pictures/qr-code.png';
import { FlowHeader } from '../components/common/FlowHeader';
import { MainContainer } from '../components/common/MainContainer';
import { InlineLink } from '../components/Details/DetailsContent';
import {
  HallOfFameContent,
  HallOfFameLocationProps,
} from '../components/HallOfFame/HallOfFameContent';
import { Routes } from '../components/routes/routes';

export const HallOfFame: FC = () => {
  const navigate = useNavigate();
  const state = useLocation().state as HallOfFameLocationProps;
  const [showVideo, setShowVideo] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const { isTablet, isMobile } = useMatchBreakpoints();

  useEffect(() => {
    if (!state && process.env.REACT_APP_ENV === 'CONSENSUS') {
      setTimeout(() => {
        setShowQR(true);
      }, 240000); // 4mins
    }
  }, []);

  useEffect(() => {
    if (!state && process.env.REACT_APP_ENV === 'CONSENSUS') {
      if (showVideo) {
        setTimeout(() => {
          setShowVideo(false);
          setShowQR(false);
        }, 180000); // 3 mins
      } else if (showQR) {
        setTimeout(() => {
          setShowVideo(true);
          setShowQR(false);
        }, 90000); // 1.5 min
      } else {
        setTimeout(() => {
          setShowVideo(false);
          setShowQR(true);
        }, 300000); // 5 mins
      }
    }
  }, [showVideo, showQR]);

  return (
    <MainContainer width={'100%'}>
      <FlowHeader cancelClick={() => navigate(Routes.Dashboard)} />
      {showVideo ? (
        <Box width="100%" height={'100vh'}>
          {' '}
          <iframe
            width="100%"
            height={isTablet ? '500px' : isMobile ? '300px' : '100%'}
            src="https://www.youtube.com/embed/MNEbg2padBE?autoplay=1&mute=0"
            title="Crypto Defender"
            frameBorder="0"
            allow="autoplay"
            allowFullScreen
          ></iframe>
          <InlineLink onClick={() => setShowVideo(false)}>
            {' '}
            {'Skip >>'}
          </InlineLink>
        </Box>
      ) : showQR ? (
        <Box
          width="100%"
          height={isTablet ? '500px' : isMobile ? '300px' : '95%'}
        >
          <img src={qrcode} width="100%" height="100%" />
        </Box>
      ) : (
        <HallOfFameContent />
      )}
    </MainContainer>
  );
};
