import {
  useMatchBreakpoints,
  IconSize,
  GridWithGap,
  FlexWithGap,
  DepositButton,
  ConfirmationBox,
  LargeText,
  SmallText,
  Text,
} from '@bumper-dao/ui-kit';
import React from 'react';

import { ProtectFloorCardProps } from './types';

export const ProtectOptions: React.FC<ProtectFloorCardProps> = ({
  onChange,
  activeOption,
  options,
  title,
  description,
  units,
  disabled,
}) => {
  const { isMobile } = useMatchBreakpoints();

  return (
    <ConfirmationBox flexDirection="column" disabled={disabled}>
      {!isMobile && (
        <>
          <Text
            fontFamily="Share Tech Mono"
            fontSize="24px"
            lineHeight="36px"
            color="secondary.white"
            mb="8px"
          >
            {title}
          </Text>
          <LargeText color="secondary.white" mb="30px">
            {description}
          </LargeText>
          <FlexWithGap gap="36px" alignItems="center">
            {options.map((v, i) => (
              <DepositButton
                selected={i === activeOption}
                secondary
                size={IconSize.L}
                key={i}
                p="0 32px"
                onClick={() => onChange(i)}
              >
                {v}
                {units}
              </DepositButton>
            ))}
          </FlexWithGap>
        </>
      )}

      {isMobile && (
        <>
          <Text
            fontFamily="Share Tech Mono"
            fontSize="20px"
            lineHeight="28px"
            color="secondary.white"
            mb="8px"
          >
            Protection Floor
          </Text>
          <SmallText color="secondary.white" mb="30px">
            Your chosen floor indicates at what price your protection will
            activate below the current price. Lower floors have a lower premium.
          </SmallText>
          <GridWithGap
            gridTemplateColumns="1fr 1fr 1fr"
            gridTemplateRows="1fr 1fr"
            gridRowGaps="28px"
            gridColumnGaps="28px"
          >
            {options.map((v, i) => (
              <DepositButton
                selected={i === activeOption}
                secondary
                size={IconSize.L}
                p="4px"
                style={{ whiteSpace: 'nowrap' }}
                key={i}
                onClick={() => onChange(i)}
              >
                {v}
                {units}
              </DepositButton>
            ))}
          </GridWithGap>
        </>
      )}
    </ConfirmationBox>
  );
};
