import {
  BoxProps,
  Flex,
  FlexWithGap,
  Heading,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React, { useContext } from 'react';
// import { useLocation } from 'react-router-dom';
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from 'recharts';

import { PositionHeader, PositionInfoContainer, ChartsIcon } from './styles';

import ChartIcon from '../../../assets/icons/charts.svg';
import { DataFeedContext } from '../../context/DataFeedContext';

const Header: React.FC = () => {
  return (
    <PositionHeader>
      <FlexWithGap gap="10px" alignItems="center">
        <ChartsIcon src={ChartIcon} />
        <Heading scale={'xxl'} color="secondary.white">
          Last 7 Days Price Action
        </Heading>
      </FlexWithGap>
    </PositionHeader>
  );
};

const Content: React.FC = () => {
  // const state = useLocation().state as ProtectLocationProps;
  const { historyDataFeed } = useContext(DataFeedContext);
  const { isTablet, isMobile } = useMatchBreakpoints();
  const transformedFeed = historyDataFeed.map((x) => ({
    ...x,
    price: +parseFloat(x.price).toFixed(2),
  }));

  return (
    <>
      {transformedFeed.length > 0 && (
        <Flex
          paddingTop={'50px'}
          justifyContent="flex-start"
          alignItems="center"
        >
          <LineChart
            width={isTablet ? 500 : isMobile ? 300 : 800}
            height={300}
            data={transformedFeed}
          >
            <Line
              type="linear"
              dataKey={'price'}
              stroke="#60C770"
              strokeWidth={4}
              isAnimationActive={false}
              dot={false}
            ></Line>

            <CartesianGrid stroke="#ccc" strokeDasharray="2 2" />
            <XAxis
              stroke="#FFFFFF"
              interval={'preserveEnd'}
              domain={['1', '7']}
              ticks={Array.from(Array(7).keys()).map((x) => x + 1)}
            />
            <YAxis
              width={80}
              stroke="#FFFFFF"
              type="number"
              domain={[
                Math.min(...transformedFeed.map((x) => x.price)) - 100,
                Math.max(...transformedFeed.map((x) => x.price)) + 200,
              ]}
            />
          </LineChart>
          {/* </ResponsiveContainer> */}
        </Flex>
      )}
    </>
  );
};

const LatestPrices: React.FC<BoxProps> = ({ ...props }) => {
  return (
    <PositionInfoContainer {...props}>
      <Header />
      <Content />
    </PositionInfoContainer>
  );
};

export default LatestPrices;
