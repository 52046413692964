import { Box } from '@bumper-dao/ui-kit';
import styled from 'styled-components';

import { PixelatedContainer } from '../MainContainer';

export const Container = styled(PixelatedContainer)`
  width: 708px;
  height: 80vh;
  position: relative;

  align-items: center;

  ${({ theme }) => theme.mediaQueries.sm} {
    gap: 20px;
    width: 90%;
  }
`;

export const CloseButtonContainer = styled(Box)`
  top: 16px;
  right: 12px;
  position: absolute;
  cursor: pointer;
`;
