import React, { ReactNode } from 'react';
import styled from 'styled-components';

import jupiterBg from '../assets/background/jupiter.png';

export const BackgroundImageProvider: React.FC<{ children: ReactNode }> = (
  props,
) => {
  return <BackgroundSelector>{props.children}</BackgroundSelector>;
};

const BackgroundSelector: React.FC<{ children: ReactNode }> = (props) => {
  const changeBackground = () => {
    return <JupiterBackground {...props}></JupiterBackground>;
  };

  return changeBackground();
};

export const JupiterBackground = styled('div')`
  background-image: url(${jupiterBg});
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100vh;
  max-width: 100vw;
`;
